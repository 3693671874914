'use strict';

import $ from 'jquery';
import enquire from 'enquire.js';

export default class Banner {
	constructor() {
		let $directionArrow = $('.direction-arrow');
		let triggerHeight;

		enquire.register("screen and (min-width: 768px)", {
			match: () => {
				if ($(window).width() < 1024) {
					triggerHeight = 665;
				}
				else {
					triggerHeight = 747;
				}

				if ($(window).height() <= triggerHeight) {
					$directionArrow.addClass('active');
				} else {
					$directionArrow.removeClass('active');
				}

				$directionArrow.on('click', function(e){
					e.preventDefault();

					$('html, body').stop(true, true).animate({
						scrollTop: $('.content-wrapper').offset().top - 50
					}, 500);
				});

				$(window).on('resize', function () {
					if ($(window).width() < 1024) {
						triggerHeight = 665;
					} else {
						triggerHeight = 747;
					}

					if ($(window).height() <= triggerHeight) {
						$directionArrow.addClass('active');
					} else {
						$directionArrow.removeClass('active');
					}
				});

				$(window).on('scroll', function () {
					if ($(window).height() <= triggerHeight) {
						if ($(window).scrollTop() > 100) {
							$directionArrow.removeClass('active');
						} else {
							$directionArrow.addClass('active');
						}
					}
				});
			},
			unmatch: () => {
				$directionArrow.removeClass('active');
				$(window).off('scroll');
				$(window).off('resize');
			}
		});
	}
}
