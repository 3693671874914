'use strict';

import $ from 'jquery';
import enquire from 'enquire.js';

export default class SiteHeader {
	constructor() {
		let $siteHeader = $('.site-header'),
			// $headerTop = $('.site-header__top'),
			$headerBottom = $('.site-header__bottom'),
			$mobileMenu = $('.mobile-menu'),
			$lvl2controller = $('.lvl2-controller'),
			$lvl3controller = $('.lvl3-controller');

		enquire.register("screen and (max-width: 1023px)", {
			match: () => {
				$mobileMenu.on('click', function(e){
					e.preventDefault();

					if(!$headerBottom.hasClass('open')){
						$headerBottom.addClass('open');
						$headerBottom.slideDown(300);
					}
					else {
						$headerBottom.removeClass('open');
						$headerBottom.slideUp(300);
						$('li.open').find('.children').slideUp(300);
						$('.open').removeClass('open');
					}
				});

				$lvl2controller.map((i, ele) => {
					let $this = $(ele);

					$this.on('click', function(e){
						e.preventDefault();
						let $menu = $this.parent().find('.children__lvl2');

						if(!$menu.parent().hasClass('open')){
							$('.children__lvl2').parent().removeClass('open');
							$('.children__lvl2').slideUp('open');
							$('.children__lvl3').parent().removeClass('open');
							$('.children__lvl3').slideUp('open');
							$menu.parent().addClass('open');
							$menu.slideDown(300);
						}
						else {
							$menu.parent().removeClass('open');
							$menu.slideUp(300);
						}
					});
				});

				$lvl3controller.map((i, ele) => {
					let $this = $(ele);

					$this.on('click', function(e){
						e.preventDefault();
						let $menu = $this.parent().find('.children__lvl3');

						if(!$menu.parent().hasClass('open')){
							$('.children__lvl3').parent().removeClass('open');
							$('.children__lvl3').slideUp('open');
							$menu.parent().addClass('open');
							$menu.slideDown(300);
						}
						else {
							$menu.parent().removeClass('open');
							$menu.slideUp(300);
						}
					});
				});

			},
			unmatch: () => {
				$mobileMenu.off('click');
				$headerBottom.removeClass('open');
				$headerBottom.css('display', '');

				$lvl2controller.off('click');
				$lvl3controller.off('click');
				$('.children').parent().removeClass('open');
				$('.children').css('display', '');
			}
		});
	}
}
