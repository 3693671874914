'use strict';

import $ from 'jquery';

export default class SfForm {
	constructor() {
		let $input = $('.quote-form input'),
			$select = $('.quote-form select'),
			$copy = $('.dropdown-copy', $('.quote-form .form-group'));

		$input.map((i, ele) => {
			let $this = $(ele);

			if($this.prop('required')){
				$this.parent().addClass('required');
		    }
		});

		$select.map((i,ele) => {
			let $this = $(ele);

			let firstOption = $('option', $this).first().text(),
				firstValue = $('option', $this).first().val(),
				_clone = `<div class="dropdown-copy"><button type="button" value="${firstValue}">${firstOption}</button><i class="icon-angle-down"></i></div>`;

			$this.before(_clone);

        	let $copy = $('.dropdown-copy', $this.parent()),
        		$select = $copy.next(),
        		$button = $copy.find('button');

	        $select.on('click', (e) => {
				e.stopPropagation();

				if (!$copy.hasClass('active')){
	        		$copy.addClass('active');
				}
				else {
	        		$copy.removeClass('active');
				}

				$('.dropdown-copy').not($copy).removeClass('active');
	        });

			$select.on('change', () => {
				let $option = $select.find(':selected');

				$select.prev().find('button').text($option.text());
			});

			$(window).on('click', () => {
				if ($copy.hasClass('active')){
					$copy.removeClass('active');
				}
			});
		});
	}
}
