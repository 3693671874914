'use strict';

import $ from 'jquery';
import doT from 'dot';

export default class LoadMore {
	constructor() {
		let $listingContainer = $('.listing-container'),
			$loadmoreContainer = $('.listing-load-more'),
			$loadMore = $('.load-more', $loadmoreContainer);

		let pageSize = $listingContainer.data('pagesize'),
			url = $listingContainer.data('endpoint'),
			pageId = $listingContainer.data('pageid'),
			totalPages = $listingContainer.data('pagecount'),
			pageNo = 2,
			data = {};

		// data = { pageNo, pageSize, pageId};

		// console.log(data);

		// this.getResults(url, data, renderResults);

		if(pageNo > totalPages){
		    $loadmoreContainer.addClass('hide');
		} else {
		    $loadmoreContainer.removeClass('hide');
		}

		$loadMore.on('click', e => {
			e.preventDefault();

			data = { pageNo, pageSize, pageId };

			this.getResults(url, data, renderResults);
		});

		function renderResults(data) {
			let listingTemplate = $('#listing-template').html(),
				listingContent = '';

			listingContent = doT.template(listingTemplate)(data.results);

			$('.listing-container').append(listingContent);
			$('.match-height').matchHeight();

			pageNo++;

			// START: SHOW HIDE LOAD MORE BUTTON
			if(pageNo > data.totalPages){
			    $loadmoreContainer.addClass('hide');
			} else {
			    $loadmoreContainer.removeClass('hide');
			}
			// END
		}
	}

	getResults(url, data, cb) {
		console.log(data);
		$.ajax({
		    url,
		    data,
		    dataType: 'json',
		    cache: false,
		    success: function(data) {
		    	cb(data);
		    }
		});
	}
}



// 'use strict';

// import $ from 'jquery';
// import doT from 'dot';

// export default class LoadMore {
// 	constructor() {
// 		let $listingContainer = $('.listing-container'),
// 			$loadmoreContainer = $('.listing-load-more'),
// 			$loadMore = $('.load-more', $loadmoreContainer);

// 		let pageSize = $listingContainer.data('pagesize'),
// 			url = $listingContainer.data('endpoint'),
// 			pageNo = 1,
// 			data = {};

// 		// data = { pageNo, pageSize };

// 		// console.log(data);

// 		$.ajax({
// 			url,
// 			data,
// 			dataType: 'json',
// 			cache: false,
// 			success: function(data) {
// 				// console.log(data.results);
// 				let newsList = data,
// 					newsListClone = data.results,
// 					totalPages = data.totalPages;

// 				renderResults(newsListClone, totalPages);

// 				$loadMore.on('click', e => {
// 					e.preventDefault();

// 					renderResults(newsListClone, totalPages);
// 				});
// 			}
// 		});

// 		function renderResults(data, totalPages) {
// 			let renderList = [];

// 			let start = pageNo == 1 ? 0 : (pageSize * (pageNo - 1)),
// 				end = pageNo == 1 ? pageSize : (pageSize * pageNo);

// 			for(let i = start; i < end; i++){
// 				if(data[i]){
// 					renderList.push(data[i]);
// 				}
// 			}

// 			let listingTemplate = $('#listing-template').html(),
// 				listingContent = '';

// 			listingContent = doT.template(listingTemplate)(renderList);

// 			$('.listing-container').append(listingContent);
// 			$('.match-height').matchHeight();

// 			pageNo++;

// 			// START: SHOW HIDE LOAD MORE BUTTON
// 			if(pageNo > totalPages){
// 			    $loadmoreContainer.addClass('hide');
// 			} else {
// 			    $loadmoreContainer.removeClass('hide');
// 			}
// 			// END
// 		}
// 	}
// }
