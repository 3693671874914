'use strict';

import $ from 'jquery';
import enquire from 'enquire.js';
import Pikaday from 'pikaday';

export default class Form {
	constructor() {
		let $formContainer = $('.quote-form'),
			$form = $('.form-container', $formContainer);

		let calendarIcon = `<i class="fa fa-calendar"></i>`;

		let $dateField = $('.datepicker-custom').find('input[data-sf-role="text-field-input"]');
		$('.datepicker-custom').append(calendarIcon);

		let picker = new Pikaday({ field: $dateField[0] });

		enquire.register("screen and (max-width: 767px)", {
			match: () => {
				$form.hide();
				$formContainer.addClass('closed');

				$('.show-hide-form button').on('click', function(e){
					e.preventDefault();
					if($formContainer.hasClass('closed')){
						$formContainer.removeClass('closed');
						$form.slideDown(300);
					}
					else {
						$formContainer.addClass('closed');
						$form.slideUp(300);
					}
				});
			},
			unmatch: () => {
				$('.show-hide-form button').off('click');
				$formContainer.removeClass('closed');
				$form.show();
			}
		});
	}
}
