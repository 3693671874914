// Main javascript entry point
// Should handle bootstrapping/starting application

'use strict';

import $ from 'jquery';
import 'jquery-match-height';
import objectFitImages from 'object-fit-images';
import LoadMore from '../_modules/load-more/load-more';
import SiteHeader from '../_modules/organisms/site-header/site-header';
import Banner from '../_modules/organisms/banner/banner';
import SfForm from '../_modules/organisms/sf-form/sf-form';
import Form from '../_modules/organisms/form/form';

$(() => {
	//Polyfill for object-fit
	objectFitImages();

	new SiteHeader();
	new Banner();

	if($('form').length){
		new Form();
		new SfForm();
	}

	if($('.listing-container').length){
		new LoadMore();
	}

	$.fn.matchHeight._maintainScroll = true;
	if($('.landing-item').length){
		$('.landing-item__image').matchHeight();
	}
	$('.match-height').matchHeight();



	// START: Quote button
	var scrolling = function(){
	    var scrollTop = $(window).scrollTop();

	    if(scrollTop > $(window).height() - 250) {
	        $('.quote-tab').addClass('slide-in');
	    } else {
	        $('.quote-tab').removeClass('slide-in');
	    }
	};

	/**
	 * On Scroll
	 */
	$(window).on('scroll.window', scrolling).trigger('scroll.window');
	$('.quote-tab').on('click', function(e){
		e.preventDefault();

		let navOffset = 200;

		let $target = $($(this).attr('href'));

		$('html, body').stop(true, true).animate({
			scrollTop: $target.offset().top - navOffset
		}, 500);
	});

	// END
});
